import React from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = React.useState(null);

  React.useEffect(() => {
    if (!ref) return
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};

export default useResizeObserver;
