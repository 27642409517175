import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardImage,
  MDBCardTitle,
  MDBIcon,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import React from "react";
import Col from "react-bootstrap/Col";

const ProjectCard = ({ value }) => {
  const {
    name,
    img,
    personas,
  } = value;
  return (
    <Col md={4}>
     
     <MDBCard className="mb-4">
          <MDBCardImage
            src={img}
            alt={name}
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>{name}</MDBCardTitle>
            <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>

          <MDBIcon fas icon="car" className='rounded-circle'/>
            <div className='ms-3'>
              <p className='text-muted mb-0'>Estacionamiento</p>
            </div>
          </div>
        </MDBListGroupItem>
        <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>

          <MDBIcon fas icon="bed" className='rounded-circle' />
            <div className='ms-3'>
              <p className='text-muted mb-0'>{personas} Personas</p>
            </div>
          </div>
        </MDBListGroupItem>
        <MDBListGroupItem className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
          <MDBIcon fas icon="umbrella-beach" className='rounded-circle' />
            <div className='ms-3'>
              <p className='text-muted mb-0'>Jardin con Parrilla</p>
            </div>
          </div>
        </MDBListGroupItem>
          </MDBCardBody>
          <MDBCardFooter className='d-flex align-items-center'>
   
          <a
            className="btn btn-outline m-auto "
            href={`/${name}`}
            role="button"
            aria-label="Mas informacion sobre las cabañas"
          >
            Mas Información
          </a>
      

          </MDBCardFooter>
        </MDBCard>
    </Col>
  );
};




export default ProjectCard;
