import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const GetInTouch = ({ heading, message }) => {
const [from, setFrom] = useState()
const [text, setText] = useState()

  // const transporter = nodemailer.createTransport({
  //   host: "c1811413.ferozo.com",
  //   port: 465,
  //   secure: true,
  //   auth: {
  //     // TODO: replace `user` and `pass` values from <https://forwardemail.net>
  //     user: "contacto@solquerandi.com",
  //     pass: "Tecnico100@",
  //   },
  // });

  async function main() {
    // send mail with defined transport object
    // await transporter.sendMail({
    //   from: {from}, // sender address
    //   to: { email }, // list of receivers
    //   subject: "Mensaje - SolQuerandi.com ✔", // Subject line
    //   text: {text }, // plain text body
    //   html: {text }, // html body
    // })
    // .then(res => {
    //   if (res) {
        alert('Mensaje enviado - SolQuerandi.com ✔')
        setText('')
        setFrom('')
    //   }
    // })
    // .catch(err => {
    //   if (err) {
    //     console.log(err)
    //   }
    // });
  }


    return (
      <div className="p-4 text-light" style={{backgroundColor: 'lightseagreen'}}>
        <h2 className="display-4 pb-3 text-center ">{heading}</h2>
        <p className="lead text-center pb-3 m-4" style={{ whiteSpace: 'break-spaces', verticalAlign: 'bottom' }}>
          {message}</p>

        {/* <span className="text-decoration-none">{email}</span>. */}
        <Container className="w-50 text-light">
         <Row>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Correo Electronico</Form.Label>
              <Form.Control type="email" id='exampleForm.ControlInput1' onChange={(e) => setFrom(e.target.value)} value={from} placeholder="nombre@ejemplo.com" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={3} id="exampleForm.ControlTextarea1" aria-describedby="mensaje" value={text}  onChange={(e) => setText(e.target.value)}/>
              <Form.Text  id="mensaje" className='text-light'>
                Índice de respuesta: 100% | Tiempo de respuesta: en pocas horas
              </Form.Text>
            </Form.Group>
            
            <a
            // eslint-disable-next-line 
            className="d-block align-content-center align-items-center btn btn-outline-light btn-lg m-auto"
            onClick={(e) => main()}
            role="button"
            aria-label="Learn more about me"
          >
            Enviar mensaje
          </a>
          </Form>

</Row>
          </Container>
      </div>
    );
  };

  export default GetInTouch;
