import { Jumbotron } from "./migration";
import { Container, Row } from "react-bootstrap";

const Map = () => {

  
    return (
      <Jumbotron fluid id="mapa" className="bg-light m-0">
        <Container className="">
          <h2 className="display-4 pb-5 text-center">Ubicación</h2>
          <Row>
           
          <iframe title="MapaSolquerandi" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.914282405493!2d-57.4465628232675!3d-37.76860803145999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584cdbec543a0b1%3A0xa1da46a65f54edd5!2sSol%20Querandi%2C%20Bed%20and%20Breakfast!5e0!3m2!1ses-419!2sar!4v1699541031806!5m2!1ses-419!2sar" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </Row>
        </Container>
      </Jumbotron>
    );
  };
  
  export default Map;
  