import React, { useState } from 'react';
import { Carousel, Container, Row } from 'react-bootstrap';
import { sarahSlider } from "../../editable-stuff/config.js";
import { Jumbotron } from '../home/migration';

const Sarah = () => {

const sarahRef = React.useRef()
const [img, ] = useState(sarahSlider.images)
const [imgTwo, ] = useState(sarahSlider.imagesTwo)



return (
    <Jumbotron ref={sarahRef}  fluid id="sarah" className="bg-light m-0">
    <Container className=""> 
      
      <h2  className="display-4 pb-5 text-center">Saráh</h2>
      <Row>
      {/* <h2 className="display-4 pb-5 text-center">{heading}</h2> */}
  
        
        <div className="col-md-12">
          <Carousel>
            {img.map((value, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={value.img}
                    alt="First slide"
                    // width={imageSize.width}
                    // height={imageSize.height}
                  />
                  <Carousel.Caption>
                    <h3>{value.label}</h3>
                    <p>
                      {value.paragraph}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </Row>
      <hr/>
      <Row>
      <div className="col-md-4">
        <p className="lead" >
        La cabaña cuenta con un dormitorio muy amplio con cama King de 1,60m. Un Baño completo, Dos camas cuchetas, existe la posibilidad de agregar una cama plegable.<br/>
        Cocina equipada a full con cocina eléctrica, microondas, cafetera, pava eléctrica, tostadora, todo a estrenar, mesa circular con sillas, TV Smart de 32" con WiFi y DirecTv.<br/>
        Patio cerrado con jardín, parrilla, mesa con bancos y sillones con mesa matera.\n\nAcceso independiente, y pérgola para estacionar el vehículo.
        </p>
</div>
      <div className="col-md-8">
        <Carousel>
            {imgTwo.map((value, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={value.img}
                    alt="First slide"
                    // width={imageSize.width}
                    // height={imageSize.height}
                  />
                  <Carousel.Caption>
                    <h3>{value.label}</h3>
                    <p>
                      {value.paragraph}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
          </div>
      </Row>
    </Container>
  </Jumbotron>
  )
}

export default Sarah