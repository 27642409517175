import React, { useState } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import useResizeObserver from "../hooks/useResizeObserver";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { mainBody, repos, about, skills, leadership } from "../editable-stuff/config.js";
import { NavLink } from "./home/migration";

const Navigation = React.forwardRef((props, ref) => {
  const [isTop, setIsTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!navbarDimensions || !navbarMenuRef ) return;
      currPos.y + navbarMenuRef.current.offsetTop - navbarDimensions.bottom > 5
        ? setIsTop(true)
        : setIsTop(false);
      setScrollPosition(currPos.y);
    },
    [navBottom]
  );

  // React.useEffect(() => {
  //   if (!navbarDimensions || !navbarMenuRef) return;
  //   navBottom - scrollPosition >= ref.current.offsetTop 
  //     ? setIsTop(false)
  //     : setIsTop(true);
  // }, [navbarDimensions, scrollPosition]);

  return (
    <Navbar
      ref={navbarMenuRef}
      className={`px-3 fixed-top  ${!isTop ? "navbar-white" : "navbar-transparent"
        }`}
      expand="lg"
    >
      <Navbar.Brand className="navbar-brand" href={"/#inicio"}>
        {/* {`<${mainBody.firstName} />`} */}

        <img
          className="d-block"
          src={props.logo}
          // alt="First slide"
          width={'80vw'}
          height={'80vh'}
        />


      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto">
        {leadership.show && (
            <NavLink
              className="nav-item lead"
              href={"/#conocenos"}
            >
              Conocenos
            </NavLink>
          )}
          {repos.show && (

            <NavLink
              href={"/#cabañas"}
            >
              Cabañas
            </NavLink>
          )}

       
          {skills.show && (
            <NavLink
              className="nav-item lead"
              href={"/#servicios"}
            >
              Servicios
            </NavLink>
          )}
          
          
            <NavLink
              className="nav-item lead"
              href={"/#mapa"}
            >
              Ubicacion
            </NavLink>
         
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
