import { MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

function SkillsBar({ skill, value, isScrolled }) {

const handleIconos = () => {
  switch (skill) {
    case 'Vista a la playa': return 'home'
    break;
    case 'Vista al mar': return 'home'
    break;
    case 'Vista al valle': return 'home'
    break;
    case 'Patio o balcón privada': return 'home'
    break;
    case 'Parrilla': return 'home'
    break;
    case 'Frente a la costa': return 'home'
    break;
    case 'Cocina y comedor': return 'home'
    break;
    case 'Wifi': return 'home'
    break;
    case 'Extintor de incendios': return 'home'
    break;
    case 'Calefacción': return 'home'
    break;
    case 'Ventilador de techo': return 'home'
    break;
    case 'Televisión Smart HD de 32': return 'home'
      break;
  
    default: return 'home'
      break;
  }
}

  return (
    <div style={{ width: "95%" }}>
      <p className="lead mb-1 mt-2">
      <MDBIcon fas icon={handleIconos()} style={{marginRight: '5px', color: 'steelblue'}} className='rounded-circle'/>
      {skill}
      </p>
     
      {/* <ProgressBar
        className={!isScrolled ? "progress" : " progress-bar-animation"}
        now={value}
      /> */}
    </div>
  );
}

export default SkillsBar;
